import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { colors, mobileThresholdPixels } from './styledComponents';
import { fontWeight } from '../v2/styledComponents';

const Container = styled.div`
  color: ${({ isTransparent }) => isTransparent ? colors.navy : colors.white};
  ${({ isTransparent }) => !isTransparent && `background-color: ${colors.navy};`}
  display: flex;
  flex-direction: row;
  width: ${({ isFull, isTransparent }) => {
    if (isFull) return '100%';
    return isTransparent ? '51%' : '40vw';
  }};
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 100px;'}
  ${({ isSmall }) => isSmall && 'width: 45%;'}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ isTransparent }) => isTransparent ? '0' : '20'}px 20px;
    width: calc(100% - 40px);
    ${({ isSmall }) => isSmall && 'margin-bottom: 40px;'}
  }
`;

const Photo = styled.img`
  margin-right: 25px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 53px;
    height: 53px;
    min-width: 53px;
    min-height: 53px;
  }
`;

const GatsbyPhotoContainer = styled.div`
margin-right: 25px;
width: 80px;
height: 80px;
min-width: 80px;
min-height: 80px;

@media (max-width: ${mobileThresholdPixels}) {
  width: 53px;
  height: 53px;
  min-width: 53px;
  min-height: 53px;
}
`;

const gatsbyPhotoStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '50%',
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Text = styled.p`
  margin: 0px;
  font-family: Roboto;
  line-height: 25px;
  font-size: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isTransparent }) => isTransparent
    ? 'font-size: 12px; line-height: 15px;'
    : 'font-size: 14px; line-height: 25px;'}
    ${({ isTransparent }) => isTransparent && 'margin-bottom: 5px;'}
  }
`;

const TextFeedback = styled.p`
  margin: 0px;
  font-family: Roboto;
  line-height: 25px;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ isTransparent }) => isTransparent
    ? 'font-size: 12px; line-height: 15px;'
    : 'font-size: 14px; line-height: 25px;'}
  }
`;

const TextName = styled.span`
  margin: 0px;
  font-family: Libre Baskerville;
  font-weight: ${fontWeight.medium};
  line-height: 30px;
  font-size: 22px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    ${({ isTransparent }) => isTransparent && 'font-size: 12px; line-height: 15px; margin-bottom: 5px;'}
  }
`;

const feedbackDotSize = 6;
const FeedbackDot = styled.span`
  box-sizing: border-box;
  ${({ isTransparent, isEmpty }) => {
    if (isTransparent) {
      return !isEmpty && `background-color: ${colors.navy};`;
    }
    return `background-color: ${isEmpty ? colors.navy : colors.white};`;
  }}
  width: ${feedbackDotSize}px;
  height: ${feedbackDotSize}px;
  border-radius: 100%;
  border-width: 0.5px;
  border-style: solid;

  margin-top: 4px;
  margin-left: 4px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: ${feedbackDotSize - 2}px;
    height: ${feedbackDotSize - 2}px;
  }
`;

const Feedback = ({
  isOpened, feedback: {
    photo, name, jobDescription, feedback, rating, gatsbyPhoto,
  }, isTransparent, isSmall, isFull,
}) => (
  <Container bottomMargin={isOpened} isTransparent={isTransparent} isSmall={isSmall} isFull={isFull}>
    {photo && <Photo src={photo} alt="Retoucherie Lille Toulouse Strasbourg Rennes Nantes Nice Cannes" loading="lazy" />}
    {gatsbyPhoto && (
      <GatsbyPhotoContainer>
        <GatsbyImage
          image={getImage(gatsbyPhoto)}
          alt="Retoucherie Lille Toulouse Strasbourg Rennes Nantes Nice Cannes"
          placeholder="blurred"
          loading="lazy"
          layout="constrained"
          style={gatsbyPhotoStyle}
        />
      </GatsbyPhotoContainer>
    )}
    <Column>
      <Row>
        <TextName isTransparent={isTransparent}>{name}</TextName>
        <FeedbackDot isTransparent={isTransparent} isEmpty={rating === 0} />
        <FeedbackDot isTransparent={isTransparent} isEmpty={rating <= 1} />
        <FeedbackDot isTransparent={isTransparent} isEmpty={rating <= 2} />
        <FeedbackDot isTransparent={isTransparent} isEmpty={rating <= 3} />
        <FeedbackDot isTransparent={isTransparent} isEmpty={rating <= 4} />
      </Row>
      <Text isTransparent={isTransparent}>{jobDescription}</Text>
      <TextFeedback isTransparent={isTransparent}>
        “
        {feedback}
        ”
      </TextFeedback>
    </Column>
  </Container>
);

Feedback.propTypes = {
  isOpened: PropTypes.bool,
  feedback: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    jobDescription: PropTypes.string,
    feedback: PropTypes.string,
    rating: PropTypes.number,
    gatsbyPhoto: PropTypes.shape({}),
  }).isRequired,
  isTransparent: PropTypes.bool,
  isSmall: PropTypes.bool,
  isFull: PropTypes.bool,
};

Feedback.defaultProps = {
  isOpened: false,
  isTransparent: false,
  isSmall: false,
  isFull: false,
};

export default Feedback;
