import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import backgroundDesktopImage from '../../../assets/headers/headerV3.jpg';
import backgroundMobileImage from '../../../assets/headers/headerV3mobile.jpg';
import backgroundCurtainsDesktopImage from '../../../assets/headers/HeaderV3.curtains.jpg';
import backgroundCurtainsMobileImage from '../../../assets/headers/HeaderV3.curtains.mobile.jpg';
import backgroundTemplateImage from '../../../assets/headers/headerWithoutImage.png';
import backgroundMobileTemplateImage from '../../../assets/headers/headerWithoutImage.mobile.png';
import maduraLogo from '../../../assets/home/maduraLogo.png';
import {
  Button, mobileThresholdPixels, fontSizes, Note, colors,
} from './styledComponents';
import withAppContext from '../../../withAppContext';
import CTALink from '../../CTALink';

export const Title1 = styled.h1`
  color: ${colors.navy};
  font-weight: bold;
  font-size: 48px;
  text-align: center;
  font-family: Libre Baskerville;
  line-height: 50px;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 30px;
    text-align: center;
    font-size: 26px;
    padding: 0px 15px;
  }
`;

const Background = styled.div`
  position: relative;
  width: 100vw;
  min-height: 47vw;
  ${({ backgroundImage }) => backgroundImage
    && `
    background: url("${backgroundImage}") no-repeat;
    background-size: cover;
    `}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
  ${({ hasBanner }) => hasBanner && 'margin-top: 62px;'}
`;

const BackgroundTemplate = styled.img`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
`;

const partnersImage = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '55vw',
  height: '100%',
  objectFit: 'cover',
};

const partnersMobileImage = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '105vw',
  objectFit: 'cover',
};

const BackgroundMobile = styled.div`
  width: 100vw;
  min-height: 47vw;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    ${({ backgroundImage }) => backgroundImage
    && `
    background: url("${backgroundImage}") no-repeat;
    background-size: cover;
    `}
    height: 170vw;
    flex-direction: column-reverse;
    margin-top: 0px;
    ${({ hasBanner }) => hasBanner && 'margin-top: 50px;'}
    ${(props) => props.negativeMarginBottom && 'margin-bottom: -50px;'}
  }
`;

const BackgroundMobileTemplate = styled.img`
  position: absolute;
  width: 100%;
  height: 170vw;
  z-index: 1;
`;

const StyledTitle1 = styled(Title1)`
  font-size: ${(props) => props.small ? '36px' : '48px'};
  text-align: ${(props) => props.textAlign};
  ${(props) => props.textAlign === 'left' && 'padding-left: 15px'};

  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 30px;
    text-align: center;
    font-size: ${(props) => props.small ? '20px' : '26px'};
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Content = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  max-width: ${(props) => props.noMargin ? 650 : 544}px;
  ${(props) => props.noMargin && 'padding: 0px 0px 0px 20px;'};
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 0px 0px 20px;
  }
`;

const Flex1 = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: flex-end;
  }
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const Ul = styled.ul`
  margin-top: 38px;
  margin-bottom: 37px;
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const Li = styled.li`
  font-family: Roboto;
  line-height: 28px;
  font-size: ${fontSizes.m};
  margin-bottom: 10px;
  color: ${colors.navy};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const CurtainsLogo = styled.img`
  margin-left: 10px;
  width: 140px;
  height: 27px;
`;

const EmptyNote = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    height: 50px;
  }

  ${(props) => props.longTitle && `
    @media (max-width: 350px) {
      height: 25px;
    }
  `}
`;

const renderLandingAdviceList = () => (
  <Ul>
    <Li>1h offerte avec votre Tilliste pour analyser tout ce que vous pouvez faire avec vos pièces dormantes</Li>
    <Li>Regardez différemment votre garde robe</Li>
    <Li>Pas besoin de CB, offre exclusive sans engagement</Li>
  </Ul>
);

const ContentRender = ({
  title, hideNote, smallTitle, titleLeft, curtainsNote, preFilledSteps, extraText, adviceVersion, goToStep2,
}) => (
  <>
    <Flex1>
      <Content noMargin={smallTitle}>
        <StyledTitle1
          lowercase
          small={smallTitle}
          textAlign={titleLeft ? 'left' : 'center'}
          paddingBottom={!curtainsNote}
        >
          {title}
        </StyledTitle1>
        <StyledDiv>
          {adviceVersion
            ? renderLandingAdviceList()
            : (
              <Ul>
                <Li>
                  Prise de mesures et livraison chez vous,
                  <br />
                  7 jours sur 7 de 7h à 22h
                </Li>
                <Li>
                  {curtainsNote
                    ? 'Des couturiers professionnels, rapides et efficaces'
                    : 'Des couturiers sélectionnés avec soin'}
                </Li>
                <Li>Les mêmes prix que la retoucherie de quartier</Li>
              </Ul>
            )}
        </StyledDiv>
        {adviceVersion
          ? <Button navy onClick={goToStep2}>Prendre rendez-vous</Button>
          : <CTALink fromInteraction="Header_button" linkState={preFilledSteps} />}
        {!hideNote
          && (
            <Note center>
              partout en France
              <br />
              {extraText}
            </Note>
          )}
        {curtainsNote
          && (
            <Row>
              <Note>{'service recommandé par '}</Note>
              <CurtainsLogo src={maduraLogo} />
            </Row>
          )}
        {hideNote && !curtainsNote && <EmptyNote longTitle={title.length > 72} />}
      </Content>
    </Flex1>
    <Flex1 />
  </>
);

ContentRender.propTypes = {
  title: PropTypes.string,
  hideNote: PropTypes.bool,
  curtainsNote: PropTypes.bool,
  smallTitle: PropTypes.bool,
  titleLeft: PropTypes.bool,
  preFilledSteps: PropTypes.shape({}),
  extraText: PropTypes.string,
  adviceVersion: PropTypes.bool,
  goToStep2: PropTypes.func,
};

ContentRender.defaultProps = {
  title: 'Votre service de couturier à domicile',
  hideNote: false,
  curtainsNote: false,
  smallTitle: false,
  titleLeft: false,
  preFilledSteps: {},
  extraText: undefined,
  adviceVersion: false,
  goToStep2() { },
};

// split in 2 renders for loading optimisation => to avoid image flickering on desktop
const Header = ({
  hideNote, curtainsNote, context: { isMobile }, hasBanner, photoVersion, backgroundImage, mobileBackgroundImage, partnersBackgroundImage, ...props
}) => {
  let backgroundImageSrc = '';
  if (photoVersion === 'curtains') {
    if (isMobile) {
      backgroundImageSrc = backgroundCurtainsMobileImage;
    } else {
      backgroundImageSrc = backgroundCurtainsDesktopImage;
    }
  } else if (isMobile) {
    backgroundImageSrc = mobileBackgroundImage || backgroundMobileImage;
  } else {
    backgroundImageSrc = backgroundImage || backgroundDesktopImage;
  }

  if (partnersBackgroundImage) {
    const gatsbyImage = getImage(partnersBackgroundImage);
    if (!isMobile) {
      return (
        <Background>
          <BackgroundTemplate src={backgroundTemplateImage} alt="Service de couture à domicile à Paris, Lyon, Bordeaux et Marseille" />
          <ContentRender isMobile={isMobile} hideNote={hideNote} curtainsNote={curtainsNote} {...props} />
          <GatsbyImage
            image={gatsbyImage}
            alt="Retouche de vêtements par une couturière"
            placeholder="blurred"
            loading="lazy"
            layout="constrained"
            style={partnersImage}
          />
        </Background>
      );
    }
    return (
      <BackgroundMobile>
        <BackgroundMobileTemplate src={backgroundMobileTemplateImage} alt="Service de couture à domicile à Paris, Lyon, Bordeaux et Marseille" />
        <ContentRender isMobile={isMobile} hideNote={hideNote} curtainsNote={curtainsNote} {...props} />
        <GatsbyImage
          image={gatsbyImage}
          alt="Retouche de vêtements par une couturière"
          placeholder="dominant_color"
          loading="lazy"
          layout="constrained"
          style={partnersMobileImage}
        />
      </BackgroundMobile>
    );
  }

  if (!isMobile) {
    return (
      <Background
        backgroundImage={backgroundImageSrc}
        hasBanner={hasBanner}
      >
        <ContentRender isMobile={isMobile} hideNote={hideNote} curtainsNote={curtainsNote} {...props} />
      </Background>
    );
  }
  return (
    <BackgroundMobile
      backgroundImage={backgroundImageSrc}
      negativeMarginBottom={hideNote && !curtainsNote}
      hasBanner={hasBanner}
    >
      <ContentRender isMobile={isMobile} hideNote={hideNote} curtainsNote={curtainsNote} {...props} />
    </BackgroundMobile>
  );
};

Header.propTypes = {
  context: PropTypes.shape({ isMobile: PropTypes.bool }).isRequired,
  photoVersion: PropTypes.string,
  hasBanner: PropTypes.bool,
  hideNote: PropTypes.bool,
  curtainsNote: PropTypes.bool,
  backgroundImage: PropTypes.string,
  mobileBackgroundImage: PropTypes.string,
  partnersBackgroundImage: PropTypes.shape({}),
  extraText: PropTypes.string,
};

Header.defaultProps = {
  photoVersion: undefined,
  hasBanner: false,
  hideNote: false,
  curtainsNote: false,
  backgroundImage: undefined,
  mobileBackgroundImage: undefined,
  partnersBackgroundImage: undefined,
  extraText: undefined,
};

export default withAppContext(Header);
