import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../../withAppContext';
import { fontSizes, colors, mobileThresholdPixels } from './styledComponents';
import { slashNtoBr } from '../../../services/formatting';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 220px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 40px 10px;
    width: unset;
    padding-bottom: 0px;
  }
`;

const ProgressBarContainer = styled.div`
  flex: 1;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  margin: 0px 0px 30px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 80%;
    padding: 0px 5px;
    margin-bottom: 0px;
    flex-direction: column;
  }
`;

const Progress = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align center;
  margin: 0px 120px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    margin: 0px 0px 23px;
    align-items: flex-start;
  }
`;

const ProgressDot = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-weight: bold;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  margin-bottom: 4px;
  margin-left: 16px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0px;
  }
`;

const ProgressText = styled.div`
  text-align: center;
  font-family: Roboto;
  line-height: 28px;
  font-size: ${fontSizes.m};
  color: ${colors.navy};
  position: absolute;
  top: 85px;
  width: 300px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
    position: static;
    font-size: ${fontSizes.s};
    text-align: left;
    margin-left: 15px;
    margin-top: 15px;
  }
`;

const stepsCloth = [
  { id: 1, name: 'Prenez RDV sur le site\n ou l’appli Tilli tous les jours même le\n week-end' },
  { id: 2, name: 'Un couturier se déplace\n chez vous pour prendre vos mesures\n et vous conseiller' },
  { id: 3, name: 'Vous êtes livré sous 3 jours,\n après retouche en atelier' },
];

const stepsCurtains = [
  { id: 1, name: 'Prenez RDV sur le site\n ou l’appli Tilli tous les jours même le\n week-end' },
  {
    id: 2,
    name: 'Un couturier se déplace chez\n vous pour vous conseiller et marquer\n les retouches sur vos rideaux',
  },
  {
    id: 3,
    name: 'Vos rideaux sont livrés sous 3 jours,\naprès retouche en atelier.\nVous n’avez plus qu’à les suspendre !',
  },
];

const stepsAdvice = [
  { id: 1, name: 'Prenez RDV sur le site\n tous les jours même le\n week-end' },
  {
    id: 2,
    name: 'Sortez tous vos vêtements, échangez\n avec votre couturier et faites vous\n conseiller gratuitement directement\n à domicile',
  },
  {
    id: 3,
    name: 'Maintenant que vous êtes inspirés,\n vous pouvez si vous le souhaitez\n réaliser toutes vos idées sous 3 jours\n après retouches en atelier',
  },
];

const ProgressBar = ({ context: { isMobile }, curtainsVersion, adviceVersion }) => {
  let steps = stepsCloth;
  if (curtainsVersion) { steps = stepsCurtains; }
  if (adviceVersion) { steps = stepsAdvice; }
  return (
    <Container>
      <ProgressBarContainer>
        {steps.map((stepIt) => (
          <React.Fragment key={stepIt.id}>
            <Progress>
              <ProgressDot>
                {stepIt.id}
                .
              </ProgressDot>
              <ProgressText>
                {!isMobile && slashNtoBr(stepIt.name)}
                {isMobile && stepIt.name.replace(/\n/g, '')}
              </ProgressText>
            </Progress>
          </React.Fragment>
        ))}
      </ProgressBarContainer>
    </Container>
  );
};

ProgressBar.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  curtainsVersion: PropTypes.bool,
  adviceVersion: PropTypes.bool,
};

ProgressBar.defaultProps = {
  curtainsVersion: false,
  adviceVersion: false,
};

export default withAppContext(ProgressBar);
