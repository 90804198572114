import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Title2 as Title2V3 } from './styledComponents';
import {
  mobileThresholdPixels, margins, Column, LineV, colors,
} from '../v2/styledComponents';

import maisonStandardsLogo from '../../../assets/partners/maisonStandardsParis.png';
import galeriesLafayetteLogo from '../../../assets/partners/galeriesLafayette.png';
import bhvLogo from '../../../assets/partners/Bhv.png';
import maduraLogo from '../../../assets/partners/madura.png';

const partnerImages = [
  { img: maisonStandardsLogo, alt: 'couturiers certifiés Lille Toulouse Strasbourg Rennes Nantes Nice Cannes' },
  { img: galeriesLafayetteLogo, alt: 'meilleure retoucherie de Paris' },
  { img: bhvLogo, alt: 'couturière à Paris, Lyon, Bordeaux et Marseille' },
  { img: maduraLogo, alt: 'retouches rideaux à Paris, Lyon, Bordeaux et Marseille' },
];

const Container = styled.div`
  padding: 74px 0px 50px 0px;
  background: ${(props) => props.linen ? colors.linen : colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 0px 30px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.partnersBlock && `
    width: 225px;
    height: 120px;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.partnersBlock && `
      width: 25vw;
      height: unset;
    `}
  }
`;

const Title2 = styled(Title2V3)`
  margin: 0 0 70px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0 0 40px;
    width: ${({ curtainsVersion }) => curtainsVersion ? '300' : '200'}px;
    font-size: ${({ theme }) => theme.fontSizes.m};
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: 0px 0px ${margins.s};
  }
`;

const Img = styled.img`
  max-height: 120px;
  height: auto;
  min-width: 150px;
  max-width: 275px;
  object-fit: contain;

  @media (max-width: ${mobileThresholdPixels}) {
    max-height: 65px;
    max-width: 180px;
  }
`;

const StyledLineV = styled(LineV)`
  height: 100px;
  margin: 0px 125px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 1px;
    width: 52px;
    margin: 20px 0px;
    background-color: ${({ theme }) => theme.colors.lightGrey3};
  }
`;

const PartnerBlock = (index, brands) => {
  const definedBrand = brands?.find((brand) => brand.index === index);
  return (
    <Row partnersBlock>
      <Link to="/ils-nous-font-confiance-les-partenaires-tilli/">
        <Img
          src={definedBrand?.img.publicURL || partnerImages[index].img}
          alt={partnerImages[index].alt}
          loading="lazy"
        />
      </Link>
    </Row>
  );
};

const PartnersPager = ({
  curtainsVersion, adviceVersion, linen, brands,
}) => {
  let title = 'Ils nous confient leurs retouches';
  if (curtainsVersion) { title = 'Les spécialistes des rideaux nous font confiance'; }
  if (adviceVersion) { title = 'Ils nous font confiance'; }
  return (
    <Container lightGrey linen={linen}>
      <Column justifyContent="center" alignItems="center">
        <Title2 curtainsVersion={curtainsVersion}>{title}</Title2>
      </Column>
      <SubContainer>
        {(curtainsVersion && !brands)
          ? <Row>{PartnerBlock(3)}</Row>
          : (
            <>
              {PartnerBlock(0, brands)}
              {' '}
              <StyledLineV />
              {PartnerBlock(1, brands)}
              {' '}
              <StyledLineV />
              {PartnerBlock(2, brands)}
            </>
          )}
      </SubContainer>
    </Container>
  );
};

PartnersPager.propTypes = {
  linen: PropTypes.bool,
  curtainsVersion: PropTypes.bool,
  adviceVersion: PropTypes.bool,
  brands: PropTypes.arrayOf(PropTypes.shape({
    img: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    index: PropTypes.number,
  })),
};

PartnersPager.defaultProps = {
  linen: false,
  curtainsVersion: false,
  adviceVersion: false,
  brands: undefined,
};

export default PartnersPager;
